












































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
// import SignService from '@common-src/service/sign';
// import SignService from '@common-src/service/sign-v2';
import { UserStoreModule } from '@common-src/store/modules/user';
import { UserModel } from '@common-src/model/user-model';
import { getUserInfo, getPermissions, initProject, login, getPublicKey } from '@common-src/service3/user/user';
import { Module } from 'vuex-module-decorators';
import { ModuleType } from '@common-src/enum/module-type.enum';
import CommonService from '@common-src/service/common';
import _JSEncrypt from '@common-src/utils/jsencrypt.min';
// const packageJson = require('../../../../package.json');

@Component
export default class LoginComponent extends BaseComponent {
    form = {
        username: '',
        password: '',
        remember: true
    };
    rules = {
        username: [
            { required: true, message: '请输入手机号', trigger: 'change' }
            // { pattern: MOBILE_REGEXP, message: '请输入正确手机号', trigger: 'change' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }]
    };

    mounted() {
        window.addEventListener('keydown', this.keyDown);
        // UserStoreModule.SET_REDIRECT_PATH(_.get(this.$route, 'query.redirectPath'));
    }

    destroyed() {
        window.removeEventListener('keydown', this.keyDown);
    }

    get ProjectName(): string {
        return UserStoreModule.CorpName;
    }

    keyDown(e) {
        if (e.keyCode === 13) {
            this.loginClick();
        }
    }

    encrypt(txt, publicKey) {
        // 加密方法
        const encryptor = new _JSEncrypt();
        encryptor.setPublicKey(publicKey);
        return encryptor.encrypt(txt);
    }

    async loginClick() {
        let validateResult = false;
        try {
            validateResult = await (this.$refs.ruleForm as any).validate();
        } catch (e) {
            return false;
        }
        if (validateResult) {
            if (this.form.remember) {
                // this.setCookie(this.form.username, this.form.password, 7);
            } else {
                this.clearCookie();
            }
            const params = {
                device: 'PC',
                username: this.form.username,
                password: this.form.password
            };

            try {
                const { publicKey } = await getPublicKey();
                params.username = this.encrypt(params.username, publicKey);
                params.password = this.encrypt(params.password, publicKey);
            } catch (error) {
                console.log(error);
                return;
            }

            return login(params)
                .then(async(res) => {
                    UserStoreModule.SET_USERINFO(new UserModel().toModel(res?.result));
                    UserStoreModule.SET_REDIRECT_PATH();
                    // UserStoreModule.SET_CURRENT_PROJECT(null);
                    const userInfo = await getUserInfo();
                    UserStoreModule.SET_USER_EXTRA_INFO(userInfo);
                    if (!_.get(UserStoreModule.UserInfo, 'IsSuperAdmin') && JTL.moduleType !== ModuleType.EDGE) {
                        // 初始化项目信息
                        // 默认选中第一个项目
                        await initProject();
                    }
                    await CommonService.getUserPermissions2();
                    this.$router.push(UserStoreModule.RedirectPath);
                })
                .catch(err => {
                    throw err;
                });
        }
    }

    setCookie(cname:string, cpwd:string, exdays:number) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);

        window.document.cookie = 'userName = ' + cname + ';path=/;expires=' + exdate.toUTCString();
        window.document.cookie = 'userPwd = ' + cpwd + ';path=/;expires=' + exdate.toUTCString();
    }

    getCookie() {
        if (window.document.cookie.length > 0) {
            var arr = document.cookie.split('; ');
            for (var i = 0; i < arr.length; i++) {
                var arr2 = arr[i].split('=');
                if (arr2[0] === 'userName') {
                    this.form.username = arr2[1];
                } else if (arr2[0] === 'userPwd') {
                    this.form.password = arr2[1];
                    this.form.remember = true;
                }
            }
        }
    }

    clearCookie() {
        this.setCookie('', '', -1);
    }
}
