var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-component" },
    [
      _c("div", { staticClass: "login-title" }, [
        _vm._v(" " + _vm._s(_vm.ProjectName) + " "),
      ]),
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            {
              staticClass: "has-border-bottom",
              attrs: { prop: "username", "has-feedback": true },
            },
            [
              _c("a-icon", {
                staticStyle: { color: "rgba(0,0,0,.25)" },
                attrs: { type: "user" },
              }),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _c("a-input", {
                attrs: {
                  placeholder: "请输入手机号",
                  name: "username",
                  size: "large",
                },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { staticClass: "has-border-bottom", attrs: { prop: "password" } },
            [
              _c("a-icon", {
                staticStyle: { color: "rgba(0,0,0,.25)" },
                attrs: { type: "lock" },
              }),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _c("a-input-password", {
                attrs: {
                  placeholder: "请输入密码",
                  name: "password",
                  size: "large",
                },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { staticClass: "remember", attrs: { prop: "remember" } },
            [
              _c(
                "a-checkbox",
                {
                  attrs: { "default-checked": false },
                  model: {
                    value: _vm.form.remember,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "remember", $$v)
                    },
                    expression: "form.remember",
                  },
                },
                [
                  _c("span", { staticClass: "remembertext" }, [
                    _vm._v("记住密码"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "jtl-button",
            {
              staticClass: "login-btn",
              attrs: {
                type: "primary",
                "click-prop": _vm.loginClick.bind(this),
              },
            },
            [_vm._v("登 录")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }